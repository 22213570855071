import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_12/b12_1.webp");
const image2 = require("../../../assets/images/blog_12/b12_2.png");
const image3 = require("../../../assets/images/blog_12/b12_3.png");
const image4 = require("../../../assets/images/blog_12/b12_4.png");
const image5 = require("../../../assets/images/blog_12/b12_5.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Intergate Freshdesk with Chatbot and automate ticketing tasks"
        description="Learn how Freshdesk customers are using Workativ Assistant’s chatbots to take care of repetitive Freshdesk tasks so that your teams can focus on complex and critical tasks."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Using Workativ Assistant to integrate Freshdesk with
                            other apps
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <h2 class="font-section-sub-header-small">
                          How Freshdesk integration with Workativ Assistant can
                          help speed up workplace support{" "}
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          Did you know that after a bad experience with a brand,
                          consumers are{" "}
                          <a href="https://freshdesk.com/resources/customer-service-statistics">
                            four times
                          </a>{" "}
                          likely to stop shopping with them? So how can you
                          provide the experience that your customers want? By
                          keeping your employees engaged. Because engaged
                          employees{" "}
                          <a href="https://haiilo.com/blog/employee-engagement-8-statistics-you-need-to-know/#:~:text=Engaged%20employees%20outperform%20their%20peers,improves%20morale%20in%20the%20workplace.&text=In%20fact%2C%20a%20Gallup%20study,workplaces%20saw%2041%25%20lower%20absenteeism.">
                            provide better customer service.
                          </a>{" "}
                          And better customer service translates to customer
                          retention. Get it?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you’re using Freshdesk for all things customer
                          service, good for you. But did you know you can use
                          the same Freshdesk for employee support as well? Yes,
                          you can, with some help from{" "}
                          <a href="https://workativ.com/">
                            Workativ Assistant,
                          </a>{" "}
                          of course.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a no-code AI-powered platform
                          for building contextual chatbots with automated
                          workflows for a wide range of business apps. Workativ
                          Assistant’s chatbots can be added to any of your
                          instant messaging apps like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack
                          </a>{" "}
                          or Microsoft Teams so that employees can self-serve
                          their IT/HR issues, on-the-go, anytime.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          By combining the Freshdesk ticketing tool with
                          Workativ Assistant, you make sure your employees have
                          access to an{" "}
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            interactive IT self-service
                          </a>{" "}
                          interface instead of static portals. Let’s take a look
                          at what all you can do with Freshdesk + Workativ
                          Assistant.
                        </p>
                        <p class="font-section-sub-header-small line-height-2">
                          Freshdesk actions that can be automated with Workativ
                          Assistant
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Creating a Freshdesk support ticket —
                          </span>
                          Logging in and out of Freshdesk every time when you
                          want to create a Freshdesk ticket can be a bit
                          cumbersome. Workativ Assistant’s Freshdesk chatbot can
                          get rid of this by letting you create a ticket just by
                          telling it to, on your Slack or Microsoft Teams app.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Retrieving a Freshdesk ticket’s details —
                          </span>
                          Whenever your employee wants to check their ticket’s
                          status, they can just ask Workativ Assitant’s chatbot
                          and it can retrieve the details for them in an
                          instant.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Updating a Freshdesk ticket —
                          </span>
                          Make it easier on your help desk agents by enabling
                          them to update a ticket as closed just by having a
                          quick chat with Workativ Assistant’s chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Deleting a Freshdesk ticket —
                          </span>
                          In case an employee mistakenly created a ticket, they
                          can delete the ticket in a flash by conversing with
                          Workativ Assistant’s Freshdesk chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Adding new contact —
                          </span>
                          New employee joining your company? Let your help desk
                          agents or your HR personnel add them to your Freshdesk
                          workspace in the blink of an eye by chatting with
                          Workativ Assistant’s chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Updating a contact’s details —
                          </span>
                          Whenever an employee changes her/his address or any
                          contact details, your HR or help desk agent can make
                          the change to your business’ Freshdesk database just
                          by handing over the details to be changed to Workativ
                          Assistant’s chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Removing a contact —
                          </span>
                          You can remove an outgoing employee from your
                          Freshdesk workspace with Workativ Assistant’s
                          Freshdesk chatbot.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen what all Freshdesk automations you
                          can set up with Workativ Assistant, let’s take a look
                          at how you can do the same.
                        </p>

                        <h5 class="font-section-sub-header-small line-height-2">
                          How Workativ Assistant creates a synergy between
                          Freshdesk and other business apps
                        </h5>
                        <h5 class="font-section-sub-header-small line-height-2">
                          Freshdesk + Access management apps + Workativ
                          Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Why stop with only Freshdesk when you can bring any
                          number of apps into the picture with Workativ
                          Assistant? With Workativ Assistant, you can create
                          automations that create a synergy between different
                          apps which wouldn’t otherwise have been possible if
                          you used them separately. For instance, let’s say you
                          want to create an automation that allows an employee
                          to unlock her/his account in{" "}
                          <a href="https://workativ.com/conversational-ai-platform/active-directory-chatbot">
                            Microsoft Azure Active Directory.
                          </a>{" "}
                          You can set up the automation in such a way that when
                          the unlock account action fails, Workativ Assistant
                          will create a Freshdesk ticket in open status and
                          gives a heads up to your help desk team. Yes, you can
                          use multiple apps and their actions in a single
                          automation!
                        </p>
                        <img loading="lazy" src={image2} className="mb-3"></img>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small line-height-2">
                          Freshdesk + HRMS + Workativ Assistant
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          You can make it easier on your HR team by taking some
                          load off them. With Workativ Assistant, you can{" "}
                          <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                            set up automations for HRMS
                          </a>{" "}
                          apps like BambooHR, People HR, Fingercheck, PurelyHR,
                          OrangeHRM, Deputy, Zenefits, etc., For example, you
                          can set up a BambooHR automation to enable employees
                          to request time off. And you can set it up in such a
                          way that if the automation fails, Workativ Assistant
                          creates a Freshdesk ticket about the same and alerts
                          your help desk team.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small line-height-2">
                          Freshdesk + Productivity apps
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can use Workativ Assistant along with apps like
                          <a href="https://workativ.com/conversational-ai-platform/sharepoint-chatbot">
                            &nbsp;Sharepoint
                          </a>{" "}
                          or Google Drive to enable easy access to files for
                          employees. Employees can ask Workativ Assistant’s
                          chatbot to retrieve a file or the file’s link for them
                          and Workativ Assistant does this for them in an
                          instant.
                        </p>
                        <img loading="lazy" src={image5} className="mb-5"></img>
                      </div>

                      <div>
                        <h5 class="font-section-sub-header-small">
                          How you can create a chatbot for Freshdesk tasks with
                          Workativ Assistant
                        </h5>

                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Benefits of Freshdesk + Workativ Assistant
                      </h6>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. On-the-go conversational IT/HR support that
                          employees would love
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s conversational AI/chatbot offers
                          seamless 24×7 Freshdesk related actions that your
                          employees/help desk agents can ask the chatbot to do.
                          Whether they are in the workplace or on the road, they
                          can create a Freshdesk ticket or do anything on
                          Freshdesk with Workativ Assistant.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Faster triaging of issues
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/assistant/workflow-automation">
                            Workativ Assistant’s structured automation
                            workflow&nbsp;
                          </a>
                          enables proper triaging of issues to the right people.
                          Be it giving a head up to an agent about a Freshdesk
                          ticket created or deleted, you can do this and more
                          with Workativ Assistant’s chatbots.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. All from the comfort of your chat channel
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s chatbots integrate seamlessly
                          with your Slack or Microsoft Teams workspace so that
                          your employees don’t have to leave their chat hub to
                          self-serve an IT/HR issue. This gets rid of the
                          frustration that comes with logging in and out of the
                          Freshdesk portal whenever an employee wants to create
                          a Freshdesk support ticket.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Did you know that when your help desk agents are
                          satisfied with their jobs, customer satisfaction
                          increases, and turnover and absenteeism decrease
                          <a href="https://www.thinkhdi.com/library/supportworld/2017/metric-of-month-agent-job-satisfaction.aspx">
                            &nbsp;(MetricNet).
                          </a>{" "}
                          So what are you waiting for? Make your help desk as
                          well as your employees’ lives easier by augmenting
                          your workplace support with Workativ Assistant and
                          Freshdesk combined!
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Sign up for a free trial today at
                          <a href="https://workativ.com/">
                            &nbsp;workativ.com
                          </a>{" "}
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Freshdesk Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="freshdesk chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
